import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Resume" />
    <main className="resume">
      <h1 className="visually-hidden">Resume</h1>
      <section className="contact">
        <a href="mailto:allison.r.okeefe@gmail.com">allison.r.okeefe@gmail.com</a>
        <a href="https://www.linkedin.com/in/allisonokeefeuxdesigner/">LinkedIn</a>
        <a href="https://res.cloudinary.com/innocent/image/upload/v1584646180/hireallisonokeefe/allisonOKeefe_resume_kdsg7n.pdf" target="_blank" rel="noopener noreferrer">Printable Resume</a>
      </section>
      <section className="experience">
        <h2>Professional Experience</h2>
        <div className="experience__entry">
          <h3 className="experience__heading"><span className="text--bold">Senior UX Designer</span> Art Van Furniture, January 2020 &ndash; Present</h3>
          <p className="experience__summary">Responsible for the design, development and evolution of web page environments that deliver modern, engaging and dynamic user experiences.</p>
          <ul className="experience__responsibilities">
            <li>Produce and deliver low-fidelity wireframes, site flows, high-fidelity mockups, and high-fidelity prototypes.</li>
            <li>Collaborate with front-end and Salesforce developers, A/B testers, category specialists, business analysts, and product managers to create a holistic but delightful eCommerce experience for the customer.</li>
            <li>Design and specify the online experience through research and data collected using analytics repositories such as HotJar, Google Analytics, and Voice of the Customer to inform decisions.</li>
            <li>Enhance and expand the company’s web and mobile presence, as well as improve sales and customer/associate communications through developing and maintaining state of the art design on web and mobile applications.</li>
          </ul>
        </div>
        <div className="experience__entry">
          <h3 className="experience__heading"><span className="text--bold">UX Designer</span> Art Van Furniture, September 2018 &ndash; January 2020</h3>
          <ul className="experience__responsibilities">
            <li>Provided ongoing updates and enhancements for existing and future company websites, intranet systems and mobile applications.</li>
            <li>Analyzed warranty engagement, identifying solutions to increase the percent of customers who add a warranty to their purchase. Redesigned this experience within the cart, contributing to a 15% increase.</li>
            <li>Initiated omni-channel team involvement in the community, volunteering with Habitat for Humanity (Detroit) on a project in Lincoln Park.</li>
          </ul>
        </div>
        <div className="experience__entry">
          <h3 className="experience__heading"><span className="text--bold">Graphic Designer</span> Health Enhancement Systems, August 2015 &ndash; August 2018</h3>
          <p className="experience__summary">Part of a design team driven to reshape the participant experience and landscape of wellness campaigns. Conceptualized and created wellness campaigns rich in gamified interactions, targeted to professionals around the world.</p>
          <ul className="experience__responsibilities">
            <li>Lead the redesign of legacy wellness campaign mobile applications.</li>
            <li>Collaborated cross-functionally with Business Development, Marketing, and Account Management teams.</li>
            <li>Initiated company-wide events including the annual Walktober Walking Month, and the Bay City 5K Run.</li>
          </ul>
        </div>
      </section>
      <section className="tools">
        <h2>Tools</h2>
        <ul>
          <li>Sketch App</li>
          <li>Invision</li>
          <li>Photoshop</li>
          <li>Illustrator</li>
          <li>UserTesting</li>
          <li>Jira</li>
        </ul>
      </section>
      <section className="skills">
        <h2>Skills</h2>
        <ul>
          <li>Low/High-fidelity Wireframing</li>
          <li>Prototyping</li>
          <li>Information Architecture</li>
          <li>Agile/SCRUM</li>
        </ul>
      </section>
      <section className="certifications">
        <h2>Certifications</h2>
        <ul>
          <li>Accessibility Fundamentals: Disabilities, Guidelines, and Laws (Deque University)</li>
        </ul>
      </section>
      <section className="education">
        <h2>Education</h2>
        <ul>
          <li>Central Michigan University</li>
          <li>Graphic Design BFA</li>
          <li>Art History Minor</li>
          <li>2010 &ndash; 2015</li>
        </ul>
      </section>
      <section className="development">
        <h2>Professional Development</h2>
        <ul>
          <li>Active usability tester on UserTesting.com</li>
          <li>Attended HOW Design Live, 2017</li>
          <li>Member of AIGA Detroit</li>
        </ul>
      </section>
    </main>
  </Layout>
)

export default IndexPage
